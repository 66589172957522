import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Element } from 'react-scroll';
import { injectIntl } from 'gatsby-plugin-intl';
import withLocale from 'hoc/withLocale';
import SEO from 'components/seo/seo';
import Layout from 'layouts/Layout';
import BoxConfigurator from 'sections/BoxConfigurator/BoxConfigurator';
import Footer from 'components/Footer/Footer';

const FormPage = ({ currentLocale }) => {
  const data = useStaticQuery(
    graphql`
      query {
        metaPl: strapiBoxConfiguratorPageMetatags(locale: { eq: "pl" }) {
          meta_title
          meta_description
          image_alt
          meta_image {
            localFile {
              url
            }
          }
        }
        metaEn: strapiBoxConfiguratorPageMetatags(locale: { eq: "en" }) {
          meta_title
          meta_description
          image_alt
          meta_image {
            localFile {
              url
            }
          }
        }
      }
    `
  );

  let meta;
  if (currentLocale === 'pl') meta = data.metaPl;
  else if (currentLocale === 'en') meta = data.metaEn;

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
    image_alt: imgAlt,
    meta_image: metaImage,
  } = meta;
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage && metaImage.localFile.url}
        imgAlt={imgAlt}
      />
      <BoxConfigurator />
      <Element name="navigation_footer">
        <Footer />
      </Element>
    </Layout>
  );
};

export default injectIntl(withLocale(FormPage));
