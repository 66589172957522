import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledInputWrapper,
  StyledLabel,
  StyledSelect,
  StyledBorder,
  StyledError,
  StyledFieldWrapper,
} from './StyledSelect';

const Select = ({
  label = false,
  name,
  onChange,
  onChangeSizes,
  onBlur,
  placeholder,
  value,
  options,
  errorMessage = false,
  required,
}) => {
  const [hasContent, setContent] = useState(false);

  const handleInputChange = item => {
    onChangeSizes(item.label);
    onChange(name, [item]);
    if (item) {
      setContent(true);
    } else {
      setContent(false);
    }
  };

  const handleInputBlur = () => {
    onBlur(name, true);
  };

  return (
    <StyledInputWrapper>
      {label ? (
        <StyledLabel htmlFor={name}>
          {label}
          {required && '*'}{' '}
          {errorMessage && <StyledError> {errorMessage}</StyledError>}
        </StyledLabel>
      ) : (
        errorMessage && <StyledError> {errorMessage}</StyledError>
      )}
      <StyledFieldWrapper>
        <StyledSelect
          className="react-select-container"
          classNamePrefix="react-select"
          name={name}
          value={value}
          placeholder={placeholder}
          options={options}
          id={name}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
        <StyledBorder isError={errorMessage} hasContent={hasContent} />
      </StyledFieldWrapper>
    </StyledInputWrapper>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeSizes: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  required: PropTypes.bool,
};

Select.defaultProps = {
  label: null,
  errorMessage: '',
  placeholder: 'Select...',
  required: false,
};

export default Select;
