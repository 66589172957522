import styled, { css } from 'styled-components';
import Select from 'react-select';

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.smm} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 50px;
  }
`;

export const StyledLabel = styled.label`
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
`;

export const StyledBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${({ theme, isError }) =>
    isError ? theme.colors.red : theme.colors.textBrown};
  transition: 0.3s;
  ${({ hasContent, isError }) =>
    (hasContent || isError) &&
    css`
      width: 100%;
    `}
`;

export const StyledSelect = styled(Select)`
  border: none;
  border-bottom: 1px solid rgba(19, 19, 19, 0.1);
  padding-top: 3px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  max-width: 100%;
  outline: none;
  &:focus ~ ${StyledBorder} {
    width: 100%;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: transparent !important;
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  }
  .react-select__control {
    border: none;
    min-height: 0;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .react-select__value-container {
    padding: 0;
  }
  .react-select__placeholder {
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.black};
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__single-value {
    font-size: 1.6rem;
    line-height: 1.6rem;
    overflow: visible;
  }
  .react-select__menu {
    box-shadow: none;
    border-radius: 8px;
    overflow: visible;
    border: 1px solid #e5e6e7;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .react-select__menu-list {
    overflow: visible;
  }
  .react-select__option {
    overflow: visible;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black};
    letter-spacing: 0.44px;
    background-color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    &--is-selected {
      background-color: #e5e6e7;
    }
    &--is-focused {
      background-color: #e5e6e7;
    }
  }
`;

export const StyledFieldWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledError = styled('span')`
  display: inline-block;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.red};
  margin-left: 5px;
`;
