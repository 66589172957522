import styled from 'styled-components';

export const StyledInputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.smm} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 50px;
  }
`;

export const StyledLabelWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const StyledLabel = styled('h3')`
  font-size: 2.1rem;
  line-height: 2.5rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledError = styled('span')`
  display: inline-block;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.red};
  margin-left: 10px;
`;

export const StyledFieldWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledFileName = styled('span')`
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-left: 2rem;
`;

export const StyledButton = styled('label')`
  padding: 15px 40px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.textBrown};
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textBrown};
  }
`;
