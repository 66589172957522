import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import {
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledLabelWrapper,
  StyledError,
  StyledButton,
  StyledFieldWrapper,
  StyledFileName,
} from './StyledFileUploader';

const FileUploader = props => {
  const { onFileSelect, onBlur, name, errorMessage, value } = props;
  const intl = useIntl();

  const handleFileInput = e => {
    const file = e.target.files[0];
    if (file) {
      onFileSelect('file', file);
    } else {
      onFileSelect('file', null);
    }
  };

  return (
    <StyledInputWrapper>
      <StyledLabelWrapper>
        <StyledLabel>
          {intl.formatMessage({ id: 'form_file_uploader_label' })}
        </StyledLabel>
        {errorMessage && <StyledError>{errorMessage}</StyledError>}
      </StyledLabelWrapper>
      <StyledInput
        name={name}
        id="uploader"
        type="file"
        onBlur={e => onBlur(e)}
        onChange={handleFileInput}
      />

      <StyledFieldWrapper>
        <StyledButton htmlFor="uploader">
          {intl.formatMessage({ id: 'form_file_uploader_add_file' })}
        </StyledButton>
        {value ? (
          <StyledFileName>{value.name}</StyledFileName>
        ) : (
          <StyledFileName>
            {intl.formatMessage({ id: 'form_file_uploader_add_file_label' })}
          </StyledFileName>
        )}
      </StyledFieldWrapper>
    </StyledInputWrapper>
  );
};

export default FileUploader;
